@use '../variables/colors.scss' as colors;
@use '../variables/typography.scss' as typography;

.datepicker-days {
  table {
    tbody {
      tr {
        .active.day {
          background-color: colors.$blue;
        }
        .today {
          background-color: colors.$gray;
          border: 0;
        }
      }
    }
  }
}

.bootstrap-datetimepicker-widget {
  .timepicker-hour,
  .timepicker-minute {
    font-size: typography.$middle-font;
  }
  button[data-action] {
    background-color: colors.$blue;
    border: 0;
    font-size: typography.$middle-font;
    padding: 0.5rem;
  }
}

.large-font {
  font-size: typography.$large-font;
}

.blue {
  color: colors.$blue;
}
.red {
  color: colors.$red;
}

.green {
  color: colors.$green;
}
