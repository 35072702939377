@use '../variables/colors.scss' as colors;
@use '../variables/typography.scss' as typography;

.box {
  width: 100%;
  height: 48px;
  border: 0;
  border-radius: 2px;
  font-size: typography.$large-font;
  text-align: center;
  &--gray {
    background-color: colors.$gray-light;
  }
  &__table {
    display: table;
  }
  &__table-middle {
    display: table-cell;
    vertical-align: middle;
  }
}
