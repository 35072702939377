@use '../../variables/colors.scss' as colors;
@use '../../variables/typography.scss' as typography;

@use '../../helper/helper.scss' as helper;

.mobile-table {
  width: 100%;
  background-color: colors.$white;
  font-size: typography.$large-font;
  &__notice {
    font-size: typography.$large-font;
    margin-top: 20%;
    @include helper.bp-mobile {
      margin-top: 10%;
    }
    text-align: center;
  }
  &__footer {
    padding: 1rem 0;
    text-align: center;
    &-btn {
      background-color: colors.$white;
      color: colors.$baseColor;
      border-radius: 2px;
      border: solid 2px colors.$baseColor;
    }
  }
}
.mobile-table-item {
  width: 100%;
  padding: 0.6rem 1.8rem 1rem 1.8rem;
  box-shadow: inset 0 -1px 0 0 #dfe5eb;

  &__header {
    width: 100%;
    display: table;
    &--carNum,
    &--couponName {
      display: table-cell;
    }
    &--carNum {
      font-size: 36px;
      text-align: left;
    }
    &--couponName {
      vertical-align: top;
      padding-top: 0.3rem;
      text-align: right;
    }
  }

  &__footer {
    width: 100%;
    text-align: left;
  }
  &--no-footer {
    padding: 0.7rem 1rem;
    .mobile-table-item__header {
      &--couponName {
        vertical-align: middle;
        padding-top: 0;
      }
    }
  }

  &--refund {
    border-left: solid 3px colors.$red;
  }
  &--parking {
    border-left: solid 3px colors.$baseColor;
  }
  &--confirmed {
    background-color: colors.$gray-light;
    border-left: solid 3px colors.$gray-dark;
    color: colors.$gray-dark;
  }
}
