@use '../../variables/typography.scss' as typography;
@use '../../variables/colors.scss' as colors;

.web-table {
  width: 100%;
  background-color: colors.$white;
  border-top: solid 3px #494f5a;
  thead {
    th {
      font-weight: normal;
      font-size: typography.$small-font;
    }
  }
  tr {
    font-size: typography.$base-font;
  }
  thead > th,
  tbody > tr > td {
    vertical-align: middle;
  }
  &__button {
    &--refund {
      background-color: colors.$red;
      color: colors.$white;
      font-size: typography.$small-font;
      border-radius: 2px;
      border: 0;
      padding: 0.2rem 0.5rem 0.15rem 0.5rem;
    }
    &--parking {
      font-size: typography.$small-font;
      background-color: colors.$baseColor;
      color: colors.$white;
      border: 0;
      border-radius: 2px;
    }
  }
  &__item {
    &--refund {
      border-left: solid 3px colors.$red;
    }
    &--parking {
      border-left: solid 3px colors.$baseColor;
    }
    &--confirmed {
      background-color: colors.$gray-light;
      border-left: solid 3px colors.$gray-dark;
      color: colors.$gray-dark;
    }
  }
}
