@mixin bp-mobile {
  @media only screen and (max-width: 520px) {
    @content;
  }
}

@mixin bp-web {
  @media only screen and (min-width: 520px) {
    @content;
  }
}
