.dropdown {
  &-btn__caret {
    float: right;
    margin-top: 0.6rem;
  }
  &__list {
    &--text-center {
      text-align: center;
    }
  }
  &__list-item {
    padding: 1rem;
    border-bottom: 1px solid #e8e9e9;
    &:hover {
      background-color: rgba(0, 0, 0, 0.03);
    }
  }
}
