@use '../variables/colors.scss' as colors;
@use '../variables/typography.scss' as typography;

@use '../helper/helper.scss' as helper;

.status-header {
  width: 100%;
  font-size: typography.$large-font;
  padding: 0 0 1rem 1.5rem;
  display: table;
  @include helper.bp-mobile {
    margin: -0.5rem 0 0 0;
    padding: 0 0 0.3rem 1rem;
  }
  &--refund {
    @include helper.bp-mobile {
      margin: -0.3rem 0 0.2rem 0;
      padding: 0 0 0.3rem 1rem;
    }
  }
  &__left,
  &__right {
    display: table-cell;
    vertical-align: middle;
  }
  &__right {
    text-align: right;
  }
  &__desc {
    @include helper.bp-mobile {
      font-size: typography.$middle-font;
    }
  }
  &__highlight {
    color: colors.$baseColor;
  }
  &__refresh-time {
    font-size: typography.$base-font;
    display: inline-block;
    margin-right: 0.3rem;
  }
  &__refresh {
    background-color: colors.$white;
    font-size: typography.$base-font;

    border-radius: 2px;
    border: solid 1px #2d3d51;

    margin-right: 3rem;
    padding: 0.15rem 0.6rem 0.13rem 0.6rem;

    @include helper.bp-mobile {
      margin-right: 1rem;
      margin-top: -0.5rem;
    }
  }
}
