@use '../variables/colors.scss' as colors;
@use '../variables/typography.scss' as typography;

@use '../helper/helper.scss' as helper;

$searchChangeMaxWidth: 300px;

.search-change-status {
  width: 100%;
  padding: 1.5rem 1.5rem 1rem 1.5rem;
  @include helper.bp-mobile {
    padding: 10px;
  }
  display: table;
  &__select {
    width: calc(100% - 77px);
    max-width: $searchChangeMaxWidth;
    height: 36px;

    background-color: colors.$white;
    font-size: typography.$middle-font;

    border-radius: 2px;
    border: solid 1px colors.$gray-dark;
    text-align: left;
    padding: 0 1rem;
    display: table-cell;
    vertical-align: middle;
    overflow: hidden;

    div {
      display: inline-block;
      vertical-align: middle;
    }
    &-text {
      width: 95%;
    }
    &-caret {
      float: right;
      margin: 12px 0;
    }
  }
  &__btn {
    width: 72px;
    height: 36px;
    color: colors.$white;
    border-radius: 2px;
    border-width: 0;
    font-size: typography.$small-font;
    margin-left: 5px;
    padding: 0;
    vertical-align: middle;
    &--red {
      background-color: colors.$red;
    }
    &--blue {
      background-color: colors.$baseColor;
    }
  }
  &__desc {
    font-size: typography.$middle-font;
    vertical-align: middle;
  }
}
