@use 'bootstrap-datepicker/dist/css/bootstrap-datepicker.min.css';
@use 'bootstrap-datetime-picker/css/bootstrap-datetimepicker.min.css';
@use 'nvd3/build/nv.d3.css';
@use 'slick-carousel/slick/slick.scss';
@use 'slick-carousel/slick/slick-theme.scss';

@use './style/helper/helper.scss' as helper;

@use './style/variables/colors.scss' as colors;
@use './style/variables/typography.scss' as typography;
@use './style/variables/breakpoints.scss' as breakpoints;
@use './style/variables/size.scss' as size;

@use './style/base/base.scss';

@use './style/layout/layout.scss';

@use './style/components/status-header.scss';
@use './style/components/modal/modal-center.scss';
@use './style/components/modal/modal-card.scss';
@use './style/components/table/web-table.scss';
@use './style/components/table/mobile-table.scss';
@use './style/components/search-change-status.scss';
@use './style/components/search.scss';
@use './style/components/dropdown.scss';
@use './style/components/box.scss';

// https://github.com/kenwheeler/slick/issues/982
* {
  min-height: 0;
  min-width: 0;
}

.field {
  &-wrapper {
    height: size.$fullHeightWithNav;
    margin: 0 -15px;
    &::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: none;
    scrollbar-width: none;
    &__title {
      font-size: typography.$massive-font;
      margin-bottom: 0.3rem;
      &--mobile {
        font-size: typography.$middle-font;
        display: inline-block;
        margin-left: 5px;
        vertical-align: middle;
      }
    }
    &-no-parkinglot {
      width: 100%;
      height: size.$fullHeightWithNav;

      background-color: colors.$gray;

      font-size: typography.$large-font;

      padding: 30% 0;
      text-align: center;
      &__notice {
        @include helper.bp-mobile {
          font-size: typography.$large-font;
          text-align: center;
        }
      }
      &__desc {
        margin: 1rem;
      }
      &__btn {
        width: 280px;
        color: colors.$white;
        border-radius: 2px;
        margin: 0.5rem;
        padding: 0.5rem 1rem;
        display: inline-block;
        @include helper.bp-mobile {
          font-size: typography.$large-font;
          text-align: center;
        }
        &--blue {
          background-color: #2d3d51;
        }
        &--red {
          background-color: colors.$red;
        }
      }
    }
  }
  &-header {
    width: 100%;
    background-color: colors.$white;
    padding-bottom: 2px;
    box-shadow: inset 0 -2px 0px 0 #dfe5eb;
    @include helper.bp-mobile {
      position: fixed;
      margin-top: -10px;
      padding-top: 10px;
    }
  }
}

.table-wrapper {
  background-color: colors.$gray;
  width: 100%;
  height: calc(100vh - 195px);
  overflow: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }

  @include helper.bp-mobile {
    margin-top: 135px;
  }
  &--refund {
    @include helper.bp-mobile {
      height: calc(100vh - 148px);
      margin-top: 87px;
    }
  }
  @include helper.bp-web {
    padding: 3rem;
    height: 85%;
  }
  display: inline-block;
  &__notice {
    font-size: typography.$large-font;
    margin-top: 20%;
    @include helper.bp-mobile {
      margin-top: 10%;
    }
    text-align: center;
  }
}

#sale-off-modal {
  .desc {
    font-size: typography.$huge-font;
    font-weight: typography.$bold-font;
    margin: 1.8rem 0;
  }
  .resale {
    width: 100%;
    display: table;
    img {
      width: 24px;
      margin: 0 0.5rem 5px 0;
    }
    .desc {
      color: colors.$gray-dark;
      font-weight: normal;
      display: table-cell;
      vertical-align: middle;
    }
    .resaleOn {
      color: colors.$baseColor;
      font-weight: typography.$bold-font;
    }
  }
  .saleOff {
    background-color: colors.$red;
    color: colors.$white;
  }
}

#resale-modal {
  .resaleHeader {
    font-size: typography.$huge-font;
    font-weight: typography.$bold-font;
    margin: 1.5rem 0 0.7rem 0;
  }
  .reserveSaleTime {
    color: colors.$blue;
    font-size: typography.$huge-font;
    border-bottom: solid 1px colors.$blue;
    margin-bottom: 1rem;
    display: inline-block;
  }
  .reserveSaleTime:hover {
    color: colors.$gray-dark;
    border-bottom: solid 1px colors.$gray-dark;
  }
  .resaleNow {
    background-color: colors.$blue;
    color: colors.$white;
  }
}

#resale-reserve-modal {
  .resaleWithReserveHeader {
    font-size: typography.$huge-font;
    font-weight: typography.$bold-font;
    margin-top: -1rem;
    margin-bottom: 1rem;
  }
  .resaleTime {
    color: colors.$blue;
    font-size: typography.$huge-font;
    margin-bottom: 1rem;
  }
  .modal-card-action {
    background-color: colors.$blue;
    color: colors.$white;
  }
  .changeReserve {
    border-right: solid 1px #0074ff;
  }
}

#set-restartsaledate-modal {
  .modal-header {
    font-size: typography.$middle-font;
    border-bottom: solid 1px colors.$gray-dark;
    text-align: center;
  }
  .modal-card__container {
    width: 100%;
    padding: 1.5rem;
  }
  .reserve {
    background-color: colors.$blue;
    color: colors.$white;
  }

  .set-restartsaledate {
    &__btn {
      width: 100%;
      height: 48px;
      background-color: colors.$gray-light;
      border: 0;
      border-radius: 2px;
      font-size: typography.$large-font;
      text-align: center;
      &--date {
        margin-bottom: 1rem;
      }
      &--complete {
        background-color: colors.$blue;
        color: colors.$white;
      }
      &:hover {
        background-color: colors.$gray;
      }
    }
    &__desc {
      width: 100%;
      height: 48px;
      background-color: colors.$gray-light;
      border: 0;
      border-radius: 2px;
      text-align: center;
      margin-bottom: 1rem;
    }
  }
  .datepicker {
    font-size: typography.$base-font;
  }
}

#select-parkinglot-modal {
  .modal-content {
    height: 400px;
  }
  .modal-card__container {
    width: 100%;
    font-size: typography.$middle-font;
    padding: 0 0 2rem 0;
    .search-bar {
      padding: 0 1.5rem;
    }
    .parkinglot-list {
      width: 100%;
      max-height: 250px;
      margin-top: 0.3rem;
      overflow: auto;
      .parkinglot-item {
        width: 100%;
        height: 56px;
        font-size: typography.$base-font;
        padding-left: 1.7rem;
        display: flex;
        align-items: center;
      }
      .parkinglot-item:hover {
        background-color: colors.$gray-light;
      }
    }
  }
}

#confirm-parking-modal {
  .modal-card__container {
    font-size: typography.$huge-font;
    .header {
      margin-top: 0.2rem;
      .notConfirmed {
        color: colors.$baseColor;
      }
    }
    .carNum {
      font-size: typography.$massive-font;
      margin: 1rem 0;
    }
    .divider {
      width: 64px;
      height: 2px;
      border: solid 1px colors.$gray-dark;
      margin-bottom: 1rem;
    }
    .parkinglotName {
      height: 102px;
      margin: 0.3rem 0;
    }
  }

  .modal-card-action {
    height: 60px;
    background-color: colors.$blue;
    color: colors.$white;
    display: table;
    text-align: center;
    .confirmed {
      background-color: #dfe5eb;
      &:hover {
        opacity: 1;
      }
    }
  }
}

#refund-modal {
  .modal-card__container {
    font-size: typography.$huge-font;
    .header {
      .notConfirmed {
        color: colors.$baseColor;
      }
    }
    .carNum {
      font-size: typography.$massive-font;
      margin: 1.2rem 0;
    }
    .divider {
      width: 64px;
      height: 2px;
      border: solid 1px colors.$gray-dark;
      margin-bottom: 1.5rem;
    }
    .desc {
      height: 102px;
      margin: 1rem 0 3rem 0;
    }
  }
  .deny {
    background-color: colors.$gray-light;
  }
  .approve {
    background-color: colors.$red;
    color: colors.$white;
  }
}
