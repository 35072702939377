@use '../variables/colors.scss' as colors;
@use '../variables/typography.scss' as typography;
@use '../variables/size.scss' as size;

.search-bar {
  width: 100%;
  max-width: size.$searchChangeMaxWidth;
  height: 36px;
  .input-group {
    margin: 10px 0;

    .transparent {
      background-color: colors.$gray !important;
      border: 0;
      padding: 0 0.3rem;
      box-shadow: inset 0 1px 0 rgba(0, 0, 0, 0.075);
      img {
        width: 24px;
      }
    }

    .form-control {
      background-color: colors.$gray;
      font-size: typography.$middle-font;
      border: 0;
      border-radius: 2px;
      border-left: none !important;
      border-right: none !important;
      padding: 0;
      box-shadow: inset 0 1px 0 rgba(0, 0, 0, 0.075);
      &[type='number'] {
        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
      }
    }
    .form-control::-webkit-input-placeholder,
    .form-control::-moz-placeholder,
    .form-control:-ms-input-placeholder,
    .form-control:-moz-placeholder {
      color: colors.$gray-dark;
    }
    .form-control-feedback {
      width: 6px;
      color: colors.$gray-dark;
    }
  }
  &__btn {
    width: 76px;
    height: 28px;
    border-radius: 2px;
    font-size: typography.$base-font;
    padding-top: 0.5rem;
    &--delete {
      background-color: colors.$red;
      color: colors.$white;
    }
  }
}
