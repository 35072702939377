@use '../variables/size.scss' as size;

html {
  position: relative;
  min-height: size.$fullHeight;
}

body {
  display: flex;
  flex-direction: column;
  min-height: size.$fullHeight;
  padding-top: 55px;
}

.masterContainer {
  display: flex;
  flex-grow: 1;
}
.root-content {
  flex-grow: 1;
  padding: 10px;
}
