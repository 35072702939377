@use '../../variables/colors.scss' as colors;
@use '../../variables/typography.scss' as typography;

.modal-card {
  .modal-dialog {
    width: 300px;
    margin: 0;
    font-size: typography.$large-font;
  }
  .modal-content {
    background-color: colors.$white;
    border-radius: 2px;
  }
  &--long {
    .modal-content {
      height: 500px;
    }
  }
  &__header {
    font-size: typography.$middle-font;
    font-weight: typography.$bold-font;
    border-bottom: solid 1px colors.$gray-dark;
    text-align: center;
    &-close-btn {
      margin: 0 !important;
      display: inline-block;
      float: right;
    }
  }
  &__container {
    padding: 1rem 2.5rem 1.5rem 2.5rem;
  }

  &__close-btn {
    text-align: right;
    margin-right: -1rem;
    img {
      width: 24px;
      float: none;
      opacity: 1;
    }
    &:hover {
      opacity: 0.5;
    }
  }

  &-action {
    width: 100%;
    height: 50px;
    display: table;
    text-align: center;
    &__btn {
      display: table-cell;
      vertical-align: middle;
      &-2 {
        width: 50%;
        font-size: typography.$large-font;
      }
      &:hover {
        opacity: 0.5;
      }
      &--close {
        background-color: colors.$gray-light;
      }
      &--red {
        background-color: colors.$red;
        color: colors.$white;
      }
      &--blue {
        background-color: colors.$baseColor;
        color: colors.$white;
      }
    }
  }
}
